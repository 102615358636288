.typeProduct {
    background: antiquewhite;
    padding: 12px;
    margin-bottom: 20px;
}
.disInlineBlock{
    display: inline-block
}
.btnAddVariant{
    display: block;
    width: 100%;
}
.btnVariant{
    margin-right: 5px;
}
.mainVariant{
    display: flex;
    flex-wrap: wrap;
}

.mainVariant>input{
    max-width: 20%;
    margin-right: 5px;
}
.selectTableField{
    width: 100%;
}

.wrapVariant {
    border-radius: 3px;
    padding: 10px 15px;
    background: #fff;
    margin-bottom: 10px;
}

.headVariant {
    margin-bottom: 15px;
}