.gutter-example .ant-row > div {
    background: transparent;
    border: 0;
}
.gutter-box {
    background: white;
    padding: 25px 0;
}
.ant-card {
    margin-bottom: 16px;
}
.card_pd0 .ant-card-body {
    padding: 0!important;
}
.tbl_top_subject td {
    padding: 10px 16px !important;
}
.ant-list-item-action {
    margin-left: 20px;
}
.antd-pro-pages-dashboard-analysis-style-salesExtra {
    display: inline-block;
    margin-right: 24px;
}

.antd-pro-pages-dashboard-analysis-style-salesExtra a {
    margin-left: 24px;
    color: rgba(0,0,0,.65);
}

.antd-pro-pages-dashboard-analysis-style-salesExtra a.antd-pro-pages-dashboard-analysis-style-currentDate {
    color: #1890ff;
}

