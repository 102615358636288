
.order-list .select-status, .order-modal .select-status{
    width: 100%;
}

.order-list .colItem {
    /* min-width: 280px; */
    margin: 5px 0;
}
.error{
    color: crimson;
    text-align: center;
    font-weight: bold;
}
.order-list .select-before {
    width: 90px;
}
.order-list .filter-action{
    text-align: right;
    display: flex;
    margin-left: -10px;
}
.order-list .filter-action .filter-button{
    margin-left: 10px;
}
.order-list .select-after {
    width: 100px;
}
.filter {
    margin-bottom: 10px;
}
.filter-item{
    width: 100%;
    display: flex;
}
.categoryName {
    background: wheat;
    display: inline-block;
    padding: 0 5px;
    margin-top: 10px;
}

.filter-item .filter-lable{
    position: relative;
    display: inline-flex;
    align-items: center;
    height: 29px;
    color: rgba(0,0,0,.85);
    font-size: 14px;
    min-width: 40px;
    text-align: right;
    padding-right: 10px;
    width: 94px;
}

.filter-item .filter-input{
    flex: 1 1;
    max-width: 400px;
}
.order-body .head-table{
    margin-bottom: 10px;
}
.order-body .ant-table-small{
    border:none
}
.order-body .product-status0{
    color: darkorchid;
    padding: 0 2px;
}
.order-body .product-status1{
    color: green;
    padding: 0 2px;
}

.order-body .product-status2{
    color: crimson;
    padding: 0 2px;
}

.order-body .row-per-page{
    margin-right: 20px;
}
.order-body .countStatus span{
    display: inline-block;
    padding: 2px 15px;
    margin-left: 20px;
    cursor: pointer;
    margin-bottom: 8px;
}
.order-body .countStatus{
    margin-bottom: 20px;
}
.order-body .log-update{
    font-size: 13px;
}
.order-body .order-action span {
    display: inline-block;
    padding: 2px 10px 0 10px;
}
.order-body .order-action span button {
    width: 22px;
    height: 24px;
}
.order-body .order-action span button:hover {
    color: crimson;
}
button#test-table-xls-button {
    position: relative;
}
.typeProductWrap>span{
    font-weight: bold;
    color: brown
}
.typeProductWrap {
    margin-top: 7px;
}
.imageProduct{
    cursor: pointer;
    margin: auto;
    width: 70px;
    height: 70px;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-size: cover;
}
/* .changeMultiStatus{
    display: inline-block
} */
.changeMultiStatus .selectStatus{
    width: 140px;
}
