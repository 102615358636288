.Header__header {
  padding: 0;
  box-shadow: 4px 4px 40px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  height: 72px;
  align-items: center;
  background-color: #fff !important;
}

.Header__fixed {
  position: fixed;
  top: 0;
  right: 0;
  width: calc(100% - 256px);
  z-index: 29;
  transition: width 0.2s;
}
.Header__collapsed {
  width: calc(100% - 80px);
}

.ant-layout-header {
  flex: 0 0 auto;
  line-height: 64px;
  background: #001529;
}
.save-province .active{
  background: #1890ff;
  color: #fff;
}
.save-province .error-province {color: red}
.save-province .active a {color: #fff}

.Header__button {
  width: 72px;
  height: 72px;
  line-height: 72px;
  text-align: center;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.Header__button:hover {
  color: #1890ff;
  background-color: #f9f9fc;
}

.Header__rightContainer {
  /*display: -ms-flexbox;*/
  /*display: flex;*/
  -ms-flex-align: center;
  align-items: center;
}

.Header__rightContainer:hover {
  color: #1890ff;
  background-color: #f9f9fc;
}

.ant-menu-submenu-title {
  position: relative;
  display: block;
  margin: 0;
  padding: 0 20px;
  white-space: nowrap;
  cursor: pointer;
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);

}
.ant-menu-submenu {
  transition: unset;
}
.ant-menu-submenu-title {
  height: 72px;
}

.ant-menu-horizontal {
  line-height: 72px;
  /*border-bottom: unset;*/
}
.ant-menu-submenu-title:hover {
  color: #1890ff;
}
/*.ant-menu-submenu:hover {*/
  /*border-bottom: unset;*/
/*}*/
.ant-menu {
  background: unset;
}

.ant-menu-horizontal > .ant-menu-submenu:hover {
  border-bottom: 2px solid transparent;
}
