.trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
}

.trigger:hover {
    color: #1890ff;
}

.logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px;
}
.box-logo {
  text-align: center;
  box-shadow: 0 1px 9px -3px rgba(0, 0, 0, 0.2);
  /*border-bottom: 2px solid #eff1f4;*/
}

.custom_menu {
  padding: 24px 0;
}
.ant-menu-inline {
  border-right: unset;
}

.custom_header {
  box-shadow: 4px 4px 40px 0 rgba(0, 0, 0, 0.05);
}


/*.Header__header___3khL7.Header__fixed___cwteW {*/
  /*position: fixed;*/
  /*top: 0;*/
  /*right: 0;*/
  /*width: calc(100% - 256px);*/
  /*z-index: 29;*/
  /*transition: width 0.2s;*/
/*}*/
/*.PrimaryLayout__footer___3kCmK {*/
  /*background: #fff;*/
  /*margin-top: 0;*/
  /*margin-bottom: 0;*/
  /*padding-top: 24px;*/
  /*padding-bottom: 24px;*/
  /*min-height: 72px;*/
/*}*/
/*.antd-pro-global-footer-globalFooter {*/
  /*margin: 48px 0 24px 0;*/
  /*padding: 0 16px;*/
  /*text-align: center;*/
/*}*/

/*.Sider__sider___1CUe- {*/
  /*box-shadow: rgba(24, 144, 255, 0.1) 0 0 28px 0;*/
  /*z-index: 10;*/
/*}*/

.PrimaryLayout__container {
  height: 100vh;
  -ms-flex: 1 1;
  flex: 1 1;
  width: calc(100% - 256px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.PrimaryLayout__content {
  padding: 24px;
  min-height: calc(100% - 72px);
}

.Page__contentInner {
  box-shadow: rgba(0, 0, 0, 0.01) 4px 4px 20px 0px;
  min-height: calc(100vh - 230px);
  position: relative;
  background: rgb(255, 255, 255);
  padding: 24px;
}

.PrimaryLayout__footer {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 24px;
  padding-bottom: 24px;
  min-height: 72px;
  background: rgb(255, 255, 255);
}
