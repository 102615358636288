
.order-list .select-status, .order-modal .select-status{
    width: 100%;
}
.error{
    color: crimson;
    text-align: center;
    font-weight: bold;
}
.order-list .select-before {
    width: 90px;
}
.order-list .filter-action{
    text-align: right;
    display: block;
}
.order-list .filter-action .filter-button{
    margin-left: 10px;
}
.order-list .select-after {
    width: 100px;
}
.filter{
    margin-bottom: 10px;
}
.filter-item{
    width: 100%;
    display: flex;
}

.filter-item .filter-lable{
    position: relative;
    display: inline-flex;
    align-items: center;
    height: 29px;
    color: rgba(0,0,0,.85);
    font-size: 14px;
    min-width: 40px;
    text-align: right;
    padding-right: 10px;
    width: 94px;
}

.filter-item .filter-input{
    flex: 1 1;
    max-width: 400px;
}
.order-body .head-table{
    margin-bottom: 10px;
}
.order-body .ant-table-small{
    border:none
}
.order-body .order-new{
    background-color: #D5E8D4;
    padding: 0 2px;
}
.order-body .order-pending{
    background-color: #F8CECC;
    padding: 0 2px;
}
.order-body .order-confirmed{
    background-color: #DAE8FC;
    padding: 0 2px;
}
.order-body .order-completed{
    background-color: #67AB9F;
    padding: 0 2px;
}
.order-body .order-cancel-before-confirm{
    background-color: #d19e52;
    padding: 0 2px;
}
.order-body .order-cancel-after-confirm{
    background-color: #FA6800;
    padding: 0 2px;
}
.order-body .order-sent{
    background-color: #E1D5E7;
    padding: 0 2px;
}
.order-body .row-per-page{
    margin-right: 20px;
}
.order-body .countStatus span{
    display: inline-block;
    padding: 2px 15px;
    margin-left: 20px;
    cursor: pointer;
}
.order-body .countStatus{
    margin-bottom: 30px;
}
.order-body .log-update{
    font-size: 13px;
}
.order-body .order-action span {
    display: inline-block;
    padding: 2px 10px 0 10px;
}
.order-body .order-action span button {
    width: 22px;
    height: 24px;
}
.order-body .order-action span button:hover {
    color: crimson;
}
button#test-table-xls-button {
    position: relative;
}
.variantSpan{
    color: crimson;
}
.inforProduct {
    margin-top: 10px;
}

.marignBottom5 {
    margin-bottom: 5px;
}

span.roleSelected {
    background: lightblue;
    display: inline-block;
    margin: 5px 5px 0px 5px;
    padding: 0 4px;
    border-radius: 3px;
}
.group-type-user {
    display: block;
}

.group-type-user>label {
    width: 30.333%;
    padding: 10px 0;
}