#form-login {
    text-align: center;
    margin-top: 170px;
}
.login-form {
    max-width: 300px;
    margin: 30px auto 0 auto;
}
.login-form-forgot {
    float: right;
}
.login-form-button {
    width: 100%;
}
