.Sider__logo {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Sider__brand {
  z-index: 1;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
  box-shadow: 0 1px 9px -3px rgba(0, 0, 0, 0.2);
}
.Sider__sider {
  box-shadow: rgba(24, 144, 255, 0.1) 0 0 28px 0;
  z-index: 10;
}

.Sider__menuContainer {
  height: calc(100vh - 120px);
  overflow-x: hidden;
  flex: 1 1;
  padding: 24px 0;
}
