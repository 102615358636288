.order-list .select-status,
.order-modal .select-status {
    width: 100%;
}

.error {
    color: crimson;
    text-align: center;
    font-weight: bold;
}

.order-list .select-before {
    width: 90px;
}

.order-list .filter-action {
    text-align: right;
    display: block;
}

.order-list .filter-action .filter-button {
    margin-left: 10px;
}

.order-list .select-after {
    width: 100px;
}

.filter {
    margin-bottom: 10px;
}

.filter-item {
    width: 100%;
    display: flex;
}

.filter-item .filter-lable {
    position: relative;
    display: inline-flex;
    align-items: center;
    height: 29px;
    color: rgba(0, 0, 0, .85);
    font-size: 14px;
    min-width: 40px;
    text-align: right;
    padding-right: 10px;
    width: 94px;
}

.filter-item .filter-input {
    flex: 1 1;
    max-width: 400px;
}

.order-body .head-table {
    margin-bottom: 10px;
}

.order-body .ant-table-small {
    border: none
}

.order-body .order-new {
    background-color: #D5E8D4;
    padding: 0 2px;
}

.order-body .order-pending {
    background-color: #F8CECC;
    padding: 0 2px;
}

.order-body .order-confirmed {
    background-color: #DAE8FC;
    padding: 0 2px;
}

.order-body .order-completed {
    background-color: #67AB9F;
    padding: 0 2px;
}

.order-body .order-cancel-before-confirm {
    background-color: #d19e52;
    padding: 0 2px;
}

.order-body .order-cancel-after-confirm {
    background-color: #FA6800;
    padding: 0 2px;
}

.order-body .order-sent {
    background-color: #E1D5E7;
    padding: 0 2px;
}

.order-body .row-per-page {
    margin-right: 20px;
}

.order-body .countStatus span {
    display: inline-block;
    padding: 2px 15px;
    margin-left: 20px;
    cursor: pointer;
}

.order-body .countStatus {
    margin-bottom: 30px;
}

.order-body .log-update {
    font-size: 13px;
}

.order-body .order-action span {
    display: inline-block;
    padding: 2px 10px 0 10px;
}

.order-body .order-action span button {
    width: 22px;
    height: 24px;
}

.order-body .order-action span button:hover {
    color: crimson;
}

button#test-table-xls-button {
    position: relative;
}

.variantSpan {
    color: crimson;
}

.inforProduct {
    margin-top: 10px;
}

.marignBottom5 {
    margin-bottom: 5px;
}

.online {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYEAYAAACw5+G7AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAABgAAAAYADwa0LPAAAAB3RJTUUH5goRERsbkGuxVQAAA0dJREFUWMPVmN1vS3EYx7/P066ampcLxDRSiqQkXu7dEGlms6RZIqWMcbFkqZIRm5dkKXFBJpmEBRnBxlQaslhaG0GEP0C4kCastjSdtwurjMzW83tc6CkZUmcyh8/teU7O5zl58pzv7xDGTdsywOnkYFE9B30+UeIXVVFBn3GMPs+fjx6sQc/cufnytbiHtamUTMJ+mfTyJTFFiWMxdXr0uDp98yZQ/RRIp41a0O+XXq0BZsygR3KCHjU2Uim8VBoMYhghDFut434PVlTAqpQ0Y1SaOzokpN5KaN8+YMsS4NWrP27AUn91iqW+vFyiEpFoJIIUMkhNnTpu4ULMQyfmZTJULY1UHQho4arlWri723AD3HDlHTfU1uIkRXGypQUjmI4Ri2XCxMdiwyBsmoZd4seuUEg1Vc1UTWfPFmzAcq7jueVcaansxB3ZGY//dfGx5EaMNsJJG30+rX3zkNYei/2kgcvPgJISdnGSXYnEhI+KUXKjpZJFfpX0eAC/H3j9mvOdXOfHdP3QoX9OXKcPleibNo26sgepKxzOe+fXocM6mR19fX+8VSYaO1pgz2bVJ/aoTy4X81brfd5aWfnPi+vkPLlO1XKdz8fiwBRxlJeb7WUUycoKya5bx5RBK2UWLjRbyCj0kY7RxwULGA/xFA9LSswWMsxdOoW7TieDqRMsYraPYVgugUUYqyWB1QMDZvsYxott8KbTLMVoluLeXrN9jCIOPBFHby8TaDEhHjdbyChkFxvZ4/H//0OmHyTkGr2Ta+fPm+1XCLkht+RGaysQuAgMDHwX5qJRYPZsdo9G2Z1I6NnDbOE8btoG9+CgeoH36oXHA2wqBt684W8VX9MdHcBROrBhQz6Pm40ep720krxVVbq4fpnH1ms1mxdpNbdv6wcJ0xrRn1snI6jbsUM7E3ignflx2RQ+Uh6+8sRyuKxM2uiItEUiEz5auVGhIM2iYCCg7QmktT09Pb8qN36o75dV1L93Ly1FPy3dvRtDcGHIZhu3cG6rSDtC0n7hgqwvKpL14bA+0oVuN9DAWCLbgTlz9Firp0MaJicNu93oRiW6v/utUoZOlKVSYpe02JNJcuADOWIx1WRpUE1dXfpWMWrxBVITdZjenKDIAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIyLTEwLTE3VDE3OjI3OjI3KzAwOjAw4LrbZwAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMi0xMC0xN1QxNzoyNzoyNyswMDowMJHnY9sAAAAodEVYdGRhdGU6dGltZXN0YW1wADIwMjItMTAtMTdUMTc6Mjc6MjcrMDA6MDDG8kIEAAAAAElFTkSuQmCC);
    display: inline-block;
    width: 10px;
    height: 10px;
    background-size: cover;
    background-position: center;
    margin-right: 6px;
}

.offline {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYEAYAAACw5+G7AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAABgAAAAYADwa0LPAAAAB3RJTUUH5goRERo5XBDB8AAAA4pJREFUWMPNmG9oW2UUh3/nvbeIVdptSXFZrLVpVtIvIig4V2oddJam3UKHUjNqO7COsmUoYi0qpe2mwzFxwYlorKBjJUKHJWnCuj8oM5vWqVj8INU2XTUk3Uw6U5SqbZLjl7wRKSFLS719Pl5O7n3Oew/nnhPCCvlp06aFbSGjkUaoVe2x2agY7TA2NeF5egOPlJeTgb2sLy2V8TxLuykWCuFNfgGfX7vG8/gIYZ+Pd/HpxBGPp/LmzcKx0nA4Xw+61cAf3zW8V8t6vXLX4vfJ23p66BQtovLAAT6OYWxQ1ZUeBEZhwUupFLmxGy2Dg+odiFNHd3fZ+djRgHN2dtUJBPfrL23/zmrl4zgkLrrdiCICT1HRioVz8Rvu5975eZjoC/xit5t10YUrT589my1cZBV/Xeep1nV2ciGcdK/Xu+biko0Yp/7iYhzheuwZGZEe2cKXvYHJeMmpmsb6eurj4dRpvx8OBGiXoqy5eDbSJSYu4G/EbDaTJ/b15Xafb1kCPz+mf7nmOYNhaQg+Nk5M/G8nfqukS0t5kDaqdRZLOUWHLtH165kSWnoHLv6mr2/diUvSpZV8hpXU7b298jLJdqjUimfVhpmZVXeVNYa60Ix4IrHUyE08W1amijZAaW9uZsf6FpfIAy4g3E1f2mwCHXSBHrZatRbLO5GT6OSxxkaBOHZyp9mstVDe/ECPU6qiQlA3FVGVwaC1T77QOKbwltEoMIkS1DJrLZQvPIkSPMoseC/uRGUkorVQvtBTsHBdOCzwAH+MfcGg1kL5wnEM045gULAJr+GE36+1UL6Qlc+w4vcLOY/LD4TWYjnF5YdMDwBer5CLBOvwO00NDGgtmJNmbMOCy2XpmLNdmYtEMrOQ8j4lxZ/9/Zl5fL1xFTv5s3ic/hKDS68ePiwvZxKQ0x1vpTbhbGnB26jhkWRSa+/MxvYQykVXa6tp/6+ffrX3xo1lCUi2boi2BfznztFm3gezw6FZIunn0h/cxW0HD1ZUxI4GnMubTc6VcmqupLD6g4YGTPN23ON2ZzamtSJdKrhKCXrRbjc7o0OXT4yOZgsXue4nd9LktwVPqHVmM9rxIXDsGN2HV+BYXFyt73+7n8ul2CmhqlVVucQzv1/pgycGdJ5q3ZYtBTswTjM2m5wOaZoOQW8yYQ6fUM2/f6tAhz0cCIXYxCcRm56mzRjDkz6fbIeyq+Tr8Q9j/pDZTJaF8QAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMi0xMC0xN1QxNzoyNjo1NyswMDowMAW9uUAAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjItMTAtMTdUMTc6MjY6NTcrMDA6MDB04AH8AAAAKHRFWHRkYXRlOnRpbWVzdGFtcAAyMDIyLTEwLTE3VDE3OjI2OjU3KzAwOjAwI/UgIwAAAABJRU5ErkJggg==);
    display: inline-block;
    width: 10px;
    height: 10px;
    background-size: cover;
    background-position: center;
    margin-right: 6px;
}